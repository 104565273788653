<template>
  <b-container fluid class="text-dark">
    <b-row>
      <b-col lg="12" sm="12">
        <!-- <iq-card> -->
          <!-- <template v-slot:body> -->
            <b-overlay :show="loader">
              <b-table-simple bordered small hover class="request_dtls_wrapper">
                <tbody>
                  <b-tr>
                      <b-th>{{$t('external_research.project_title')}}</b-th>
                      <b-td colspan="3">{{ $i18n.locale === 'en' ? data.project_title : data.project_title_bn }} </b-td>
                  </b-tr>
                  <b-tr>
                      <b-th>{{$t('external_research.project_id')}}</b-th>
                      <b-td>{{ data.proposal_auto_id }}</b-td>
                      <b-th>{{$t('external_research.thematic_area')}}</b-th>
                      <b-td>{{ $i18n.locale === 'en' ? data.thematic_area_name : data.thematic_area_name_bn }} </b-td>
                  </b-tr>
                  <b-tr>
                      <b-th>{{ $t('external_research.expense_breakdown') }}</b-th>
                      <b-td>
                        <a v-if="data.attachment" target="_blank" rel="noopener noreferrer"
                          :href="baseUrl +'storage/research-panel/original/'+ data.attachment" >
                          {{ $t('globalTrans.attachment') }}
                          <i class="ri-download-cloud-line" ></i>
                        </a>
                      </b-td>
                      <b-th>{{$t('globalTrans.status')}}</b-th>
                      <b-td><span class="badge badge-warning">{{ data.request_status }}</span></b-td>
                  </b-tr>
                  <b-tr>
                      <b-th>{{$t('certify_form.remarks')}}</b-th>
                      <b-td>{{ data.remarks }}</b-td>
                      <b-th>{{$t('research_manage.reject_note')}}</b-th>
                      <b-td>{{ data.reject_note }}</b-td>
                  </b-tr>
                </tbody>
              </b-table-simple>
              <b-table-simple v-if="data.details" bordered>
                  <thead>
                    <b-tr class="bg-primary">
                        <b-th colspan="7">
                            <h5 class="text-white text-center">{{ $t('external_research.fund_request') }}
                            </h5>
                        </b-th>
                    </b-tr>
                      <tr class="bg-gray">
                          <th class="text-center" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                          <th scope="col">{{ $t('research_manage.budget_type') }}</th>
                          <th scope="col">{{ $t('external_research.budget_head') }}</th>
                          <th scope="col">{{ $t('globalTrans.fiscal_year') }}</th>
                          <th scope="col">{{ $t('external_research.allocation_amount') + ' ' + $t('globalTrans.taka') }}</th>
                          <th scope="col">{{ $t('external_research.available_balance') + ' ' + $t('globalTrans.taka') }}</th>
                          <th scope="col">{{ $t('external_research.requisition_amount') + ' ' + $t('globalTrans.taka') }}</th>
                      </tr>
                  </thead>
                  <b-tbody>
                      <b-tr v-for="(item, index) in data.details" :key="index">
                          <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                          <b-td>{{ getBudgetTypeName(item.budget_type_id) }}</b-td>
                          <b-td>{{ getBudgetName(item.budget_head_id) }}</b-td>
                          <b-td>{{ getExpenseYearName(item.fiscal_year_id) }}</b-td>
                          <b-td>{{ Number.isInteger(parseFloat(item.allocation_amount)) ? ($n(item.allocation_amount) + (currentLocale === 'bn' ? '.০০' : '.00')) : $n(item.allocation_amount) }}</b-td>
                          <b-td>{{ Number.isInteger(parseFloat(item.available_balance)) ? ($n(item.available_balance) + (currentLocale === 'bn' ? '.০০' : '.00')) : $n(item.available_balance) }}</b-td>
                          <b-td>{{ Number.isInteger(parseFloat(item.requisition_amount)) ? ($n(item.requisition_amount) + (currentLocale === 'bn' ? '.০০' : '.00')) : $n(item.requisition_amount) }}</b-td>
                      </b-tr>
                      <b-td colspan="6">
                          <p class="text-right m-0">{{ $t('external_research.total_requisition') + ' ' + $t('globalTrans.taka') }}</p>
                      </b-td>
                      <b-td colspan="1" class="bg-warning">
                        {{ Number.isInteger(parseFloat(data.total_requisition)) ? ($n(data.total_requisition) + (currentLocale === 'bn' ? '.০০' : '.00')) : $n(data.total_requisition) }}
                      </b-td>
                  </b-tbody>
                </b-table-simple>
            </b-overlay>
          <!-- </template> -->
        <!-- </iq-card> -->
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { fundAllocatedAmountApi } from '../../api/routes'
export default {
    props: ['id'],
     created () {
        if (this.id) {
            const tmp = this.getData()
            this.data = tmp
            this.getAllocatedAmount()
        }
    },
    data () {
        return {
            baseUrl: agriResearchServiceBaseUrl,
            loader: false,
            data: {},
            status: ''
        }
    },
    computed: {
        currentLocale: function () {
          return this.$i18n.locale
        }
    },
    methods: {
      getBudgetName (Id) {
            const Obj = this.$store.state.AgriResearch.commonObj.budgetHeadList.find(item => item.value === parseInt(Id))
            if (this.$i18n.locale === 'en') {
                  return Obj.text_en
              } else {
                  return Obj.text_bn
              }
        },
        getExpenseYearName (Id) {
            const Obj = this.$store.state.commonObj.fiscalYearList.find(item => item.value === Id)
            if (this.$i18n.locale === 'en') {
                  return Obj.text_en
              } else {
                  return Obj.text_bn
            }
        },
        getBudgetTypeName (Id) {
            const Obj = this.$store.state.AgriResearch.commonObj.budgetTypeList.find(item => item.value === parseInt(Id))
            if (this.$i18n.locale === 'en') {
                return Obj.text_en
            } else {
                return Obj.text_bn
            }
        },
        getGrandTotal () {
            let sumM = 0
            this.data.details.forEach(function (data) {
                sumM = sumM + parseFloat(data.requisition_amount)
            })
            this.data.total_requisition = parseFloat(sumM)
        },
        getData () {
          const tmpData = this.$store.state.list.find(item => item.id === this.id)
          return JSON.parse(JSON.stringify(tmpData))
        },
        async getAllocatedAmount () {
        this.loader = true
        const param = {
          project_id: this.data.proposal_main_id,
          id: this.id
        }
        const result = await RestApi.getData(agriResearchServiceBaseUrl, fundAllocatedAmountApi, param)
        if (result.success && result.data !== null) {
          this.data.details = result.data
          this.getGrandTotal()
        } else {
          this.data.details = []
          this.$toast.error({
            title: 'Error',
            message: 'Budget is not available.'
          })
        }
        this.loader = false
      }
    }
}
</script>
<style scoped>
.request_dtls_wrapper tr th {
  width: 20%;
}
.request_dtls_wrapper tr td {
  width: 30%;
}
</style>
